<template>
  <section class="section-casino">
    <div class="section-casino__list container-custom">
      <SectionTitle title="Sảnh Live casino" />
      <div class="content">
        <div class="content__left">
          <nuxt-link v-for="(game, index) of providerCasino.filter((game) => game.alias !== 'all')" :key="index" :to="`${PAGE.CASINO}/${game.alias}`" class="content__left--item">
            <CommonBaseImg :src="game?.icon" class="icon-casino" alt="icon-casino" />
            <div class="group-text" lazy>
              <p>
                {{ game?.display_name }}
              </p>
            </div>
          </nuxt-link>
        </div>
        <div class="content__right">
          <CommonBaseImg src="/assets/images/components/desktop/pages/home/visual-section-casino.webp" class="visual-casino" alt="visual-casino" />
        </div>
      </div>
    </div>
  </section>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useAppStore } from '@/store/app'
import { useGameStore } from '~~/store/game'
import { PAGE } from '~/constants/router'
const CommonBaseImg = defineAsyncComponent(() => import('~/components/common/base-img.vue'))
const SectionTitle = defineAsyncComponent(() => import('@/components/common/title-section.vue'))
const { $pinia } = useNuxtApp()
const store = useAppStore($pinia)
const storeGame = useGameStore($pinia)
const { providerCasino } = storeToRefs(storeGame)
const router = useRouter()
</script>
<style scoped lang="scss" src="assets/scss/components/desktop/pages/home/section-casino/index.scss"></style>
